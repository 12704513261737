import React, { Component } from "react"
import styled from "styled-components"
import IntroText2Col from "../components/IntroText2Col"
import FlexibleContent from "../components/FlexibleContent"
import Header from "../components/header"
import locomotiveScroll from "locomotive-scroll"
import { HeaderFunc } from "../scrollFunctions/headerFunc"
import IconWrap from "../components/IconWrap"
import CurrentPage from "../components/CurrentPageIdentifier"
import { Helmet } from "react-helmet"
import gsap from "gsap"
import { graphql } from "gatsby"

const ImageSmall = styled.div`
  position: relative;
`

const ImageLarge = styled.div`
  position: relative;
`

const ImageWrap = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
  display: grid;
  min-height: 50vh;
  z-index: 0;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  .image-wrap {
    background: var(--bg-color);
    max-height: 50vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &.image-large {
      grid-column: span 2;
    }
    &.image-small {
      grid-column: span 1;
    }

    .rollover-img {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .label.rollover {
      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 10;
      opacity: 0;
      color: var(--text-color);
      transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transform: translateY(100%);
    }
  }
`

export default class Homepage extends Component {
  constructor() {
    super()
    this.state = { scroll: {} }
    this.scrollRef = React.createRef()
  }

  componentDidMount() {
    const images = Array.from(document.querySelectorAll(".image-wrap"))

    const checkInviewImages = () => {
      if (typeof window !== "undefined") {
        const inviewImages = images.filter(image => {
          const imgRect = image.getBoundingClientRect()
          const wh = window.innerHeight

          return imgRect.top <= wh && imgRect.top > 0
        })

        gsap.to(inviewImages, { opacity: 1, y: 0, stagger: 0.3, delay: 0.5 })
      }
    }

    const scroll = new locomotiveScroll({
      el: this.scrollRef.current,
      smooth: true,
      smoothMobile: true,
      smartphone: {
        smooth: true,
      },
      tablet: {
        smooth: true,
      },
    })

    scroll.on("call", func => {
      if (func === "headerFunc") {
        HeaderFunc()
      }
    })

    scroll.on("scroll", checkInviewImages)

    this.setState({ scroll })
  }

  componentDidUpdate() {
    this.state.scroll.update()
    // this.state.scroll.update()
    // this.state.scroll.start()
  }

  componentWillUnmount() {}

  render() {
    const { data } = this.props

    let about = data.page.acf_about

    return (
      <div>
        <Helmet>
          <title>About | David Lindsay</title>
          <meta
            name="description"
            content="About David Lindsay | Photographer | Tadcaster | Yorkshire | @photosbydavid "
          />
        </Helmet>
        <IconWrap />
        <CurrentPage
          title={
            this.props.data.page.title === "Home"
              ? "David Lindsay"
              : this.props.data.page.title
          }
        />
        <div className="scroller" ref={this.scrollRef}>
          <Header
            className="header"
            state={this.state}
            headerText={
              this.props.data.page.title === "Home"
                ? "David Lindsay"
                : this.props.data.page.title
            }
          />
          <IntroText2Col
            col1={data.page.acf_about.textColumn1}
            col2={data.page.acf_about.textColumn2}
          />
          {data.page.acfFlexibleContent ? (
            <FlexibleContent
              id={data.page.id}
              acfFlexibleContent={data.page.acfFlexibleContent}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }
}

export const query = graphql`
  query AboutQuery {
    page: wpPage(title: { eq: "About" }) {
      id
      title
      content
      acf_about {
        textColumn1
        textColumn2
      }
      # ACF Flexible Content
      acfFlexibleContent {
        pageBuilder {
          # Flexible Content Image Row FullWidth
          ... on WpPage_Acfflexiblecontent_PageBuilder_ImageRowFullwidth {
            imageCaption
            fieldGroupName
            image {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageHoverIsDark
            imageHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageFocalPoint
            imageHoverFocalPoint
          }

          # Flexible Content Image Row 21
          ... on WpPage_Acfflexiblecontent_PageBuilder_ImageRow21 {
            imageLargeCaption
            imageSmallCaption
            fieldGroupName
            imageLarge {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageLargeHoverIsDark
            imageSmallHoverIsDark
            imageLargeHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageSmall {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageSmallHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageLargeFocalPoint
            imageLargeHoverFocalPoint
            imageSmallFocalPoint
            imageSmallHoverFocalPoint
          }

          # Flexible Content Image Row 12
          ... on WpPage_Acfflexiblecontent_PageBuilder_ImageRow12 {
            imageLargeCaption
            imageSmallCaption
            fieldGroupName
            imageLarge {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageLargeHoverIsDark
            imageSmallHoverIsDark
            imageLargeHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageSmall {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageSmallHover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            imageLargeFocalPoint
            imageLargeHoverFocalPoint
            imageSmallFocalPoint
            imageSmallHoverFocalPoint
          }

          # Flexible Content Image Row 111
          ... on WpPage_Acfflexiblecontent_PageBuilder_ImageRow111 {
            image1FocalPoint
            image1HoverFocalPoint
            image2FocalPoint
            image2HoverFocalPoint
            image3FocalPoint
            image3HoverFocalPoint
            image1Caption
            image2Caption
            image3Caption
            fieldGroupName
            image1 {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image2 {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image3 {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image1HoverIsDark
            image2HoverIsDark
            image3HoverIsDark
            image1Hover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image2Hover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image3Hover {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
