import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Image({
  classNames,
  img1,
  img1Focal,
  img2,
  img2Focal,
  caption,
}) {
  const objectPosition = fp => {
    let focalPoint

    // Top
    if (fp === "topLeft") {
      focalPoint = "0% 0%"
    }
    if (fp === "topCentre") {
      focalPoint = "50% 0%"
    }
    if (fp === "topRight") {
      focalPoint = "100% 0%"
    }

    // Middle
    if (fp === "middleLeft") {
      focalPoint = "0% 50%"
    }
    if (fp === "middleCentre") {
      focalPoint = "50% 50%"
    }
    if (fp === "middleRight") {
      focalPoint = "100% 50%"
    }

    // Bottom
    if (fp === "bottomLeft") {
      focalPoint = "0% 100%"
    }
    if (fp === "bottomCentre") {
      focalPoint = "50% 100%"
    }
    if (fp === "bottomRight") {
      focalPoint = "100% 100%"
    }

    return focalPoint
  }

  return (
    <>
      <label className="label rollover">{caption}</label>
      <div className="image image-template">
        <GatsbyImage
          imgStyle={{
            objectPosition: objectPosition(img1Focal),
          }}
          image={img1}
          alt={caption + "-1"}
        />
      </div>
      <div className="image-hover image-template rollover-img rollover">
        <GatsbyImage
          imgStyle={{
            objectPosition: objectPosition(img2Focal),
          }}
          image={img2}
          alt={caption + "-2"}
        />
      </div>
    </>
  )
}
