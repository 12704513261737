import React, { useState, useEffect } from "react"
import ImageRow21 from "./flexibleContent/imageRow21"
import ImageRow12 from "./flexibleContent/imageRow12"
import ImageRow111 from "./flexibleContent/imageRow111"
import ImageRowFullWidth from "./flexibleContent/ImageRowFullWidth"

export default function FlexibleContent(props) {
  const acf = props.acfFlexibleContent.pageBuilder

  return (
    <div>
      {acf
        ? acf.map((node, i) => {
            console.log(node)
            if (
              node.fieldGroupName ===
              "page_Acfflexiblecontent_PageBuilder_ImageRowFullwidth"
            ) {
              return (
                <ImageRowFullWidth
                  id={props.id}
                  acfData={node}
                  key={node.fieldGroupName + i}
                  className={node.fieldGroupName + i}
                ></ImageRowFullWidth>
              )
            }
            if (
              node.fieldGroupName ===
              "page_Acfflexiblecontent_PageBuilder_ImageRow21"
            ) {
              return (
                <ImageRow21
                  id={props.id}
                  acfData={node}
                  key={node.fieldGroupName + i}
                  className={node.fieldGroupName + i}
                ></ImageRow21>
              )
            }
            if (
              node.fieldGroupName ===
              "page_Acfflexiblecontent_PageBuilder_ImageRow12"
            ) {
              return (
                <ImageRow12
                  id={props.id}
                  acfData={node}
                  key={node.fieldGroupName + i}
                  className={node.fieldGroupName + i}
                ></ImageRow12>
              )
            }
            if (
              node.fieldGroupName ===
              "page_Acfflexiblecontent_PageBuilder_ImageRow111"
            ) {
              return (
                <ImageRow111
                  id={props.id}
                  acfData={node}
                  key={node.fieldGroupName + i}
                  className={node.fieldGroupName + i}
                ></ImageRow111>
              )
            }
            return " "
          })
        : ""}
    </div>
  )
}
