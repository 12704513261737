import React from "react"
import ImageTemplate from "./ImageTemplate"
import styled from "styled-components"

const ImageSmall = styled.div`
  position: relative;
`

const ImageLarge = styled.div`
  position: relative;
`

const ImageWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 25px;
  display: block;
  min-height: 50vh;
  z-index: 0;
  grid-column-gap: 25px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: 750px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  /* &.is-inview{  
    .image-wrap{
      opacity:1;
      transform:translateY(0);
    }
  } */

  .image {
    width: 100%;
    height: 100%;
  }

  .image-wrap {
    background: var(--bg-color);
    cursor: pointer;
    max-height: 50vh;
    height: 100%;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &:first-child {
      margin-bottom: 25px;

      @media only screen and (min-width: 750px) {
        margin-bottom: 0;
      }
    }

    &.is-inview {
      /* opacity:1;
      transform:translateY(0); */
    }

    &.image-large {
      grid-column: span 1;
      /* transition-delay:.75s; */

      @media only screen and (min-width: 1024px) {
        grid-column: span 2;
      }
    }

    &.image-small {
      grid-column: span 1;
      /* transition-delay:.5s; */
    }

    .rollover-img {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    .image-template {
      transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .label.rollover {
      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 10;
      opacity: 0;
      color: var(--text-color);
      transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transform: translateY(100%);
    }

    &.image-light {
      .label.rollover {
        color: black;
      }
    }

    &.js_hover,
    &:hover {
      .image {
        opacity: 0;
      }
      .label.rollover {
        opacity: 1;
        transform: translateY(0);
      }
      .rollover-img {
        opacity: 1;
        /* opacity:0.3; */
      }
    }
  }
`

export default function ImageRow12(props) {
  const acf = props.acfData
  const imageLargeCaption = acf.imageLargeCaption
  const imageLargeFluid =
    acf.imageLarge.localFile.childImageSharp.gatsbyImageData
  const imageLargeHoverFluid =
    acf.imageLargeHover.localFile.childImageSharp.gatsbyImageData
  const imageSmallCaption = acf.imageSmallCaption
  const imageSmallFluid =
    acf.imageSmall.localFile.childImageSharp.gatsbyImageData
  const imageSmallHoverFluid =
    acf.imageSmallHover.localFile.childImageSharp.gatsbyImageData
  const imageSmallHoverDark = acf.imageSmallHoverIsDark
  const imageLargeHoverDark = acf.imageLargeHoverIsDark
  const imageLargeFocalPoint = acf.imageLargeFocalPoint
  const imageLargeHoverFocalPoint = acf.imageLargeHoverFocalPoint
  const imageSmallFocalPoint = acf.imageSmallFocalPoint
  const imageSmallHoverFocalPoint = acf.imageSmallHoverFocalPoint

  console.log(acf)
  const handleTouchStart = e => {
    const target = e.target.closest(".image-wrap")
    target.classList.add("js_hover")
  }
  const handleTouchEnd = e => {
    const target = e.target.closest(".image-wrap")
    target.classList.remove("js_hover")
  }

  return (
    <ImageWrap className="image-wrap-12">
      <ImageSmall
        onTouchStart={e => handleTouchStart(e)}
        onTouchEnd={e => handleTouchEnd(e)}
        data-scroll
        className={
          imageSmallHoverDark
            ? "image-wrap image-small image-dark"
            : "image-wrap image-small image-light"
        }
      >
        <ImageTemplate
          img1={imageSmallFluid}
          img2={imageSmallHoverFluid}
          img1Focal={imageSmallFocalPoint}
          img2Focal={imageSmallHoverFocalPoint}
          classNames={["test"]}
          caption={imageSmallCaption}
        />
      </ImageSmall>
      <ImageLarge
        onTouchStart={e => handleTouchStart(e)}
        onTouchEnd={e => handleTouchEnd(e)}
        data-scroll
        className={
          imageLargeHoverDark
            ? "image-wrap image-large image-dark"
            : "image-wrap image-large image-light"
        }
      >
        <ImageTemplate
          img1={imageLargeFluid}
          img2={imageLargeHoverFluid}
          img1Focal={imageLargeFocalPoint}
          img2Focal={imageLargeHoverFocalPoint}
          classNames={["test"]}
          caption={imageLargeCaption}
        />
      </ImageLarge>
    </ImageWrap>
  )
}
