import React from "react"
// import Img from "gatsby-image"
import ImageTemplate from "./ImageTemplate"
import styled from "styled-components"

const ImageWrap = styled.div`
  width: 100%;
  /* height: 100%; */
  position: relative;
  margin-bottom: 25px;
  /* margin-bottom:2rem; */
  display: block;
  min-height: 50vh;
  z-index: 0;

  @media only screen and (min-width: 750px) {
    grid-column-gap: 25px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  /* &.is-inview{  
    .image-wrap{
      opacity:1;
      transform:translateY(0);
    }
  } */

  .image-wrap {
    background: var(--bg-color);
    cursor: pointer;
    grid-column: span 1;
    max-height: 50vh;
    /* height: 100%; */
    overflow: hidden;
    position: relative;
    display: flex;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    &.is-inview {
      /* opacity:1;
      transform:translateY(0); */
    }

    @media only screen and (min-width: 750px) {
      margin-bottom: 0;
    }

    .image {
      height: 100%;
      width: 100%;
    }

    /* 
    &.image-1{
      transition-delay:.5s;  
    }
    &.image-2{
      transition-delay:.75s;
    }
    &.image-3{
      transition-delay:1s;
    } */

    .rollover-img {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    .image-template {
      transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    .label.rollover {
      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 10;
      opacity: 0;
      color: var(--text-color);
      transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
      transform: translateY(100%);
    }

    &.image-light {
      .label.rollover {
        color: black;
      }
    }

    &.js_hover,
    &:hover {
      .image {
        opacity: 0;
      }
      .label.rollover {
        opacity: 1;
        transform: translateY(0);
      }
      .rollover-img {
        opacity: 1;
        /* opacity:0.3; */
      }
    }
  }
`

const ImageSmall = styled.div`
  position: relative;
`

export default function ImageRow111(props) {
  const acf = props.acfData

  const image1Caption = acf.image1Caption
  const image2Caption = acf.image2Caption
  const image3Caption = acf.image3Caption
  const image1FocalPoint = acf.image1FocalPoint
  const image1HoverFocalPoint = acf.image1HoverFocalPoint
  const image2FocalPoint = acf.image2FocalPoint
  const image2HoverFocalPoint = acf.image2HoverFocalPoint
  const image3FocalPoint = acf.image3FocalPoint
  const image3HoverFocalPoint = acf.image3HoverFocalPoint
  const image1 = acf.image1.localFile.childImageSharp.gatsbyImageData
  const image2 = acf.image2.localFile.childImageSharp.gatsbyImageData
  const image3 = acf.image3.localFile.childImageSharp.gatsbyImageData
  const image1Hover = acf.image1Hover.localFile.childImageSharp.gatsbyImageData
  const image1HoverIsDark = acf.image1HoverIsDark
  const image2Hover = acf.image2Hover.localFile.childImageSharp.gatsbyImageData
  const image2HoverIsDark = acf.image2HoverIsDark
  const image3Hover = acf.image3Hover.localFile.childImageSharp.gatsbyImageData
  const image3HoverIsDark = acf.image3HoverIsDark
  console.log(acf)
  const handleTouchStart = e => {
    const target = e.target.closest(".image-wrap")
    target.classList.add("js_hover")
  }
  const handleTouchEnd = e => {
    const target = e.target.closest(".image-wrap")
    target.classList.remove("js_hover")
  }
  console.log("test")
  return (
    <ImageWrap className="image-wrap-111" data-scroll>
      <ImageSmall
        onTouchStart={e => handleTouchStart(e)}
        onTouchEnd={e => handleTouchEnd(e)}
        data-scroll
        className={
          image1HoverIsDark
            ? "image-wrap image-1 image-dark"
            : "image-wrap image-1 image-light"
        }
      >
        <ImageTemplate
          img1={image1}
          img2={image1Hover}
          img1Focal={image1FocalPoint}
          img2Focal={image1HoverFocalPoint}
          classNames={["test"]}
          caption={image1Caption}
        />
      </ImageSmall>
      <ImageSmall
        onTouchStart={e => handleTouchStart(e)}
        onTouchEnd={e => handleTouchEnd(e)}
        data-scroll
        className={
          image2HoverIsDark
            ? "image-wrap image-2 image-dark"
            : "image-wrap image-2 image-light"
        }
      >
        <ImageTemplate
          img1={image2}
          img2={image2Hover}
          img1Focal={image2FocalPoint}
          img2Focal={image2HoverFocalPoint}
          classNames={["test"]}
          caption={image2Caption}
        />
      </ImageSmall>
      <ImageSmall
        onTouchStart={e => handleTouchStart(e)}
        onTouchEnd={e => handleTouchEnd(e)}
        data-scroll
        className={
          image3HoverIsDark
            ? "image-wrap image-3 image-dark"
            : "image-wrap image-3 image-light"
        }
      >
        <ImageTemplate
          img1={image3}
          img2={image3Hover}
          img1Focal={image3FocalPoint}
          img2Focal={image3HoverFocalPoint}
          classNames={["test"]}
          caption={image3Caption}
        />
      </ImageSmall>
    </ImageWrap>
  )
}
