import React, { useEffect, useRef } from "react"
// import Img from "gatsby-image"
import ImageTemplate from "./ImageTemplate"
import styled from "styled-components"

const FullWidthImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  margin-bottom: 25px;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s all cubic-bezier(0.175, 0.885, 0.32, 1.275);

  /* &.is-inview{  
    opacity:1;
    transform:translateY(0);
  } */

  .image {
    height: 100%;
    width: 100%;
  }

  .label.rollover {
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 10;
    opacity: 0;
    color: var(--text-color);
    transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: translateY(100%);
  }

  &.image-light {
    .label.rollover {
      color: black;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  .image-template {
    width: 100%;
    height: 100%;
    display: grid;
    transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &.image {
      z-index: 10;
      position: relative;
      height: 100%;
    }

    &.image-hover {
      position: absolute;
      top: 0;
      z-index: 0;
      height: 100%;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      transition: 0.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }

  .image {
    overflow: hidden;
  }

  &.js_hover,
  &:hover {
    background: var(--bg-color);

    .image {
      opacity: 0;
    }
    .label.rollover {
      opacity: 1;
      transform: translateY(0);
    }
    .image-hover {
      opacity: 1;
      /* opacity:0.3; */
    }
  }
`

// const FullWidthImg = styled.div`
//   width:100%;
//   height: 100%;
//   margin-bottom:25px;
//   display:grid;
//   transition:.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);

//   &.image{
//   z-index:10;
//   position: relative;
//   height:100%;
//   }

//   &.image-hover{
//   position:absolute;
//   top:0;
//   z-index:0;
//   height:100%;
//   }

//   .gatsby-image-wrapper{
//     width:100%;
//     height:100%;
//     transition:.5s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
//     }

// `;

export default function ImageRowFullWidth(props) {
  const img1 = props.acfData.image.localFile.childImageSharp.gatsbyImageData
  const img2 =
    props.acfData.imageHover.localFile.childImageSharp.gatsbyImageData
  const caption = props.acfData.imageCaption
  const imgDark = props.acfData.imageHoverIsDark
  const imageFocalPoint = props.acfData.imageFocalPoint
  const imageHoverFocalPoint = props.acfData.imageHoverFocalPoint

  const handleTouchStart = e => {
    const target = e.target.closest(".image-wrap")
    target.classList.add("js_hover")
  }
  const handleTouchEnd = e => {
    const target = e.target.closest(".image-wrap")
    target.classList.remove("js_hover")
  }

  return (
    <FullWidthImageWrapper
      onTouchStart={e => handleTouchStart(e)}
      onTouchEnd={e => handleTouchEnd(e)}
      className={imgDark ? "image-dark image-wrap" : "image-light image-wrap"}
      data-scroll
    >
      <ImageTemplate
        img1={img1}
        img2={img2}
        img1Focal={imageFocalPoint}
        img2Focal={imageHoverFocalPoint}
        classNames={["test"]}
        caption={caption}
      />
    </FullWidthImageWrapper>
  )
}
