import React from "react";
import parse from 'html-react-parser'
import styled from "styled-components"

const IntroTextInner = styled.div`
  line-height:1.6rem;
  font-size:1.2rem;
  margin-bottom:3rem;

  .col1,
  .col2{
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 16px;
    line-height: 22px;

    @media only screen and (min-width: 1024px) {
      width: calc(50% - 4rem);
      margin-bottom: 0;
      font-size: auto;
    }
  }

  .col1{
    @media only screen and (min-width: 1024px) {
    margin-right: 4rem;
    }
  }

  p{
  margin-bottom:1rem;
  width: 100%;

    &:last-child{
    margin-bottom: 0;
    }

  }
`;

export default function IntroText({col1, col2}) {
  return (
    <IntroTextInner className="testingtesting123">
      <div className="col1">
        {
          parse(col1)
        }
      </div>
      <div className="col2">
        {
          parse(col2)
        }
      </div>
    </IntroTextInner>
  )
}

